// Assets.
import "../Auth/Auth.scss";

const authUrl = process.env.REACT_APP_APP_URL;

const Auth = ({ query }) => {
  let redirectUrl = authUrl;
  if (query) {
    redirectUrl += `?originalQuestion=${encodeURIComponent(query)}`;
  }

  return (
    <div className="intmath1-auth">
      <div className="intmath1-inner">
        <h3>Create an account to view your solution for free!</h3>

        <div className="intmath1-btn-container">
          <a
            href={redirectUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="intmath1-btn"
          >
            Create Account 👉
          </a>
          <h6>no credit card required.</h6>
        </div>

        <h4 style={{ textAlign: "center" }}>
          Already have an account?{" "}
          <a href={redirectUrl} target="_blank" rel="noopener noreferrer">
            Sign In
          </a>
        </h4>
      </div>
    </div>
  );
};

export default Auth;
