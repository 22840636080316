// Reference: https://fredrikoseberg.github.io/react-chatbot-kit-docs/docs/configure-your-bot
import { createCustomMessage } from "components/Test1";

// Custom messages.
import { InitialSystemMessage } from "./CustomMessages/SystemMessages";
import { WelcomeMessage } from "./CustomMessages/WelcomeMessages";

import Header from "components/Header/Header";
import DogPictureWidget from "components/Solver/widgets/DogPictureWidget";
import ImageWidget from "components/Solver/widgets/ImageWidget";
import OCRWidget from "components/Solver/widgets/OCRWidget";
import AIResponseWidget from "components/Solver/widgets/AIResponseWidget";
import GraphButtonWidget from "components/Solver/widgets/GraphButtonWidget";
import GraphWidget from "components/Solver/widgets/GraphWidget";
import TutorButtonWidget from "components/Solver/widgets/TutorButtonWidget";

const config = {
  initialMessages: [
    createCustomMessage("", "custom"),
    createCustomMessage("", "welcome1"),
  ],
  botName: "",

  customComponents: {
    header: () => <Header />,
    botAvatar: (props) => null,
    userAvatar: (props) => null,
  },
  customMessages: {
    custom: (props) => <InitialSystemMessage {...props} />,
    welcome1: (props) => <WelcomeMessage {...props} />,
  },

  widgets: [
    {
      widgetName: "dogPictureWidget",
      widgetFunc: (props) => <DogPictureWidget {...props} />,
    },
    {
      widgetName: "imageWidget",
      widgetFunc: (props) => <ImageWidget {...props} />,
    },
    {
      widgetName: "ocrWidget",
      widgetFunc: (props) => <OCRWidget {...props} />,
      // mapStateToProps: ["messages"],
    },
    {
      widgetName: "aiResponseWidget",
      widgetFunc: (props) => <AIResponseWidget {...props} />,
      // mapStateToProps: ["messages"],
    },
    {
      widgetName: "graphButtonWidget",
      widgetFunc: (props) => <GraphButtonWidget {...props} />,
      // mapStateToProps: ["messages"],
    },
    {
      widgetName: "graphWidget",
      widgetFunc: (props) => <GraphWidget {...props} />,
      // mapStateToProps: ["messages"],
    },
    {
      widgetName: "tutorButtonWidget",
      widgetFunc: (props) => <TutorButtonWidget {...props} />,
      // mapStateToProps: ["messages"],
    },
  ],
};

export default config;
