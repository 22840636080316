export const fetchOCR = async (data) => {
  const url = process.env.REACT_APP_OCR_API;
  const response = await fetch(url, {
    method: "POST",
    body: data,
  });

  if (!response.ok) {
    throw new Error(response.status);
  }

  return response.json();
};
