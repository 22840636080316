export const InitialSystemMessage = () => {
  return (
    <div className="intmath1-system-message-container">
      <div className="intmath1-system-message">
        <h3>Answer any math problem.</h3>
        <p>Enter your problem below to see how our AI math solver works.</p>
      </div>
    </div>
  );
};
