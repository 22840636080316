import { useEffect, useState, useRef } from "react";

import { fetchGraphable } from "api/AIQueryAPI";

const GraphButtonWidget = ({ payload, setState, actions, webAppUserId }) => {
  const [graphableApiRunning, setGraphableApiRunning] = useState(false);
  const lastMessageIdRef = useRef(payload.messageId);
  const showGraphButtonRef = useRef(null);

  /**
   * Fetch solver API.
   */
  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchGraphable(
        payload?.query,
        payload?.answer,
        webAppUserId
      );
      return data;
    };

    const removeLastLoadingMessage = () => {
      const messagesElems = document.querySelectorAll(
        ".react-chatbot-kit-chat-message-container .react-chatbot-kit-chat-bot-message-container"
      );
      let lastMessageElem = messagesElems[messagesElems.length - 1];

      if (lastMessageElem) {
        const emptyParagraph = lastMessageElem.querySelector("p:empty");

        if (emptyParagraph) {
          lastMessageElem?.remove();
        }
      }
    };

    setGraphableApiRunning(true);

    fetchData()
      .then((data) => {
        const isGraphable = data?.graphable_bool;

        if (isGraphable === true) {
          updateMessage(lastMessageIdRef.current, "");
          showGraphButtonRef.current = "show";

          removeLastLoadingMessage();
        } else {
          // Delete the loading message
          // since there is nothing to display now.
          deleteMessage(lastMessageIdRef.current);
        }
      })
      .catch((error) => {
        // Delete the loading message
        // since there is nothing to display now.
        deleteMessage(lastMessageIdRef.current);
      })
      .finally(() => {
        setGraphableApiRunning(false);
      });
  }, []);

  const handleClick = () => {
    // Call Graph Widget.
    actions.handleGraph(payload?.query, payload?.answer);
  };

  /**
   * Update bot message.
   */
  const updateMessage = (messageId, text) => {
    setState((prevState) => {
      const newMessages = [...prevState.messages];
      const messageItem = newMessages.find((item) => item.id === messageId);
      messageItem.message = text;
      messageItem.loading = false;

      return { ...prevState, messages: newMessages };
    });

    // Remove message loading.
    const loader = document.querySelector(".chatbot-loader-container");
    loader?.remove();
  };

  /**
   * Delete bot message.
   */
  const deleteMessage = (messageId) => {
    setState((prevState) => {
      const newMessages = [...prevState.messages];
      const messageIdx = newMessages.findIndex((item) => item.id === messageId);
      newMessages.splice(messageIdx, 1);

      return { ...prevState, messages: newMessages };
    });
  };

  return (
    <button
      className={`graph-button intmath1-btn ${
        showGraphButtonRef?.current ? "show" : ""
      }`}
      onClick={handleClick}
    >
      Generate Graph
    </button>
  );
};

export default GraphButtonWidget;
