import { useEffect, useRef } from "react";
import { createChatBotMessage } from "components/Test1/index.js";

const messages = [
  "Hi 👋 Can I help solve your math problem?",
  "Copy & paste it or take a picture below and get a step by step solution 👇",
];
const intervals = [1000 * 2, 1000]; // Intervals in milliseconds.

export const WelcomeMessage = ({ setState }) => {
  const lastMessageIdRef = useRef(null);
  const currentMessageIndexRef = useRef(0);

  /**
   * Display welcome messages.
   */
  useEffect(() => {
    let timer;

    const showMessage = () => {
      // Update last message.
      const messageId = lastMessageIdRef.current;
      const text = messages[currentMessageIndexRef.current];
      updateMessage(messageId, text);

      // Check if there are messages left to display.
      if (currentMessageIndexRef.current >= messages.length - 1) {
        clearTimeout(timer);
        return;
      }

      timer = setTimeout(prepareNextMessage, 1000);
    };

    const prepareNextMessage = () => {
      // Create new message.
      lastMessageIdRef.current = createMessage("");

      // Prepare for next waiting message.
      currentMessageIndexRef.current = currentMessageIndexRef.current + 1;
      const interval = intervals[currentMessageIndexRef.current];
      timer = setTimeout(showMessage, interval);
    };

    // Create new message.
    lastMessageIdRef.current = createMessage("");

    // Initial call to start the sequence.
    timer = setTimeout(showMessage, intervals[0]);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  /**
   * Create bot message.
   */
  const createMessage = (text) => {
    const botMessage = createChatBotMessage(text, {
      withAvatar: false,
    });

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));

    return botMessage.id;
  };

  /**
   * Update bot message.
   */
  const updateMessage = (messageId, text) => {
    setState((prevState) => {
      // const displayText = text.replace(/(?:\r\n|\r|\n)/g, "<br>");

      const newMessages = [...prevState.messages];
      const messageItem = newMessages.find((item) => item.id === messageId);
      // messageItem.message = displayText;
      messageItem.message = text;
      messageItem.loading = false;

      return { ...prevState, messages: newMessages };
    });

    // Remove message loading.
    const loader = document.querySelector(".chatbot-loader-container");
    loader?.remove();
  };
  return null;
};
