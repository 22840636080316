// Reference: https://fredrikoseberg.github.io/react-chatbot-kit-docs/docs/advanced/chatbot-props

import React, { useEffect, useLayoutEffect, useState, useRef } from "react";

// import Chatbot from "react-chatbot-kit";
import Chatbot from "components/Test1/index.js";
// import * as Chatbot2 from "components/Test1/index.js";
// import { Chatbot } from "components/Test1/index.js";
// import { Chatbot, createChatBotMessage } from "components/Test1/index.js";
import config from "components/Solver/config.js";
import MessageParser from "components/Solver/MessageParser.js";
import ActionProvider from "components/Solver/ActionProvider.js";

// const Chatbot = window.Chatbot;

import Paywall from "components/Paywall/Paywall";
import Auth from "components/Auth/Auth";

// require("components/Solver/config.js");
// const Chatbot = require("components/Test1/index.js");

/* import { MathfieldElement } from "mathlive";
import mathliveStyle from "mathlive/fonts.css";
MathfieldElement.soundsDirectory = null;
const mfe = new MathfieldElement({
  // mathVirtualKeyboardPolicy: "sandboxed",
  // virtualKeyboardTargetOrigin: "https://solver.intmath.test",
}); */

/* const trustedOrigins = [
  "https://www.intmath.test",
  "https://www.intmath.com",
  "https://chat.intmath.test",
  "https://chat.intmath.com",
]; */

const Solver = ({ userEmail, withinWebApp, webAppUserId, originalQuery }) => {
  const [hasPaywall, setHasPaywall] = useState(false);
  const [showAuth, setShowAuth] = useState(false);
  const [query, setQuery] = useState(null);

  const [parentUrl, setParentUrl] = useState(null);
  const [keepKeyboardOpen, setKeepKeyboardOpen] = useState(false);
  const textInputRef = useRef(null);

  /**
   * Prompt authentication.
   */
  useEffect(() => {
    if (withinWebApp) {
      // If user is within web app, we don't prompt authentication.
      return;
    }

    // User is not within web app.

    const handleOnClick = (event) => {
      event.preventDefault();
      event.stopPropagation();

      const inputElem = document.querySelector(".react-chatbot-kit-chat-input");
      setQuery(inputElem.value);

      setShowAuth(true);
    };

    const uploadButtonElem = document.querySelector(".intmath1-btn-upload");
    uploadButtonElem.addEventListener("click", handleOnClick);

    const submitButtonElem = document.querySelector(".intmath1-btn-submit");
    submitButtonElem.addEventListener("click", handleOnClick);

    return () => {
      uploadButtonElem.removeEventListener("click", handleOnClick);
      submitButtonElem.removeEventListener("click", handleOnClick);
    };
  }, [withinWebApp]);

  /**
   * Initialise mathlive keyboard.
   */
  /* useEffect(() => {
    const onMessage = (event) => {
      if (!trustedOrigins.includes(event.origin)) return;
      if (!event.data.parent) return;

      mfe.virtualKeyboardTargetOrigin = event.origin;
      window.mathVirtualKeyboard.targetOrigin = event.origin;

      setParentUrl(event.origin);

      if (event.origin.indexOf("chat") !== -1) {
        // Keep keyboard open.
        setKeepKeyboardOpen(true);
      }
    };

    window.addEventListener("message", onMessage);

    return () => {
      window.removeEventListener("message", onMessage);
    };
  }, []); */

  /* useLayoutEffect(() => {
    const inputElem = document.querySelector("#input");
    mfe.defaultMode = "text";
    inputElem.appendChild(mfe);
  }, []); */

  /**
   * Initialise mathlive field.
   */
  /* useEffect(() => {
    const onFocusIn = (event) => {
      if (keepKeyboardOpen) {
        return;
      }

      window.mathVirtualKeyboard.show();
    };

    const onFocusOut = (event) => {
      if (keepKeyboardOpen) {
        return;
      }

      window.mathVirtualKeyboard.hide();
    };

    setTimeout(() => {
      // Reference: https://cortexjs.io/mathlive/guides/virtual-keyboards/#controlling-when-the-virtual-keyboard-is-displayed
      mfe.addEventListener("focusin", onFocusIn);
      mfe.addEventListener("focusout", onFocusOut);
    }, 0);

    return () => {
      mfe.removeEventListener("focusin", onFocusIn);
      mfe.removeEventListener("focusout", onFocusOut);
    };
  }, [keepKeyboardOpen, parentUrl]); */

  /**
   * On form submit.
   */
  /* useEffect(() => {
    const formElem = document.querySelector(
      ".react-chatbot-kit-chat-input-form"
    );
    if (!formElem) return;

    const onSubmit = (event) => {
      event.preventDefault();

      const userInput = mfe.value;
      textInputRef.current.value = userInput;

      setTimeout(() => {
        // Reset value.
        mfe.value = "";
      }, 1000);
    };

    formElem.addEventListener("submit", onSubmit);
  }, []); */

  const updateHasPaywall = (val) => {
    // setHasPaywall(val);
  };

  if (hasPaywall) {
    return <Paywall />;
  } else if (showAuth) {
    return <Auth query={query} />;
  } else {
    return (
      <Chatbot
        config={config}
        messageParser={MessageParser}
        actionProvider={ActionProvider}
        headerText=" "
        placeholderText="Enter a math problem"
        updateHasPaywall={updateHasPaywall}
        userEmail={userEmail}
        textInputRef={textInputRef}
        initialQuery={originalQuery}
        withinWebApp={withinWebApp}
        webAppUserId={webAppUserId}
      />
    );
  }
};

export default Solver;
