// Reference: https://blog.logrocket.com/how-create-multilevel-dropdown-menu-react/

import { useEffect, useState } from "react";

import { menuItems } from "menuItems";
import MenuItems from "./MenuItems";

const Header = () => {
  const [showHeader, setShowHeader] = useState(false);

  useEffect(() => {
    // Get parent window's location URL.
    // const url = document.referrer;
    // if (url) {
    //   setShowHeader(true);
    // }
  }, []);

  return (
    <div className="intmath1-chat-header">
      {showHeader && (
        <div className="nav-area">
          <nav>
            <ul className="menus">
              {menuItems.map((menu, index) => {
                const depthLevel = 0;
                return (
                  <MenuItems items={menu} key={index} depthLevel={depthLevel} />
                );
              })}
            </ul>
          </nav>
        </div>
      )}
    </div>
  );
};

export default Header;
