import { useEffect } from "react";

import { fetchOCR } from "api/OCRAPI";

const OCRWidget = ({
  payload,
  setState,
  actions,
  updateHasPaywall,
  webAppUserId,
}) => {
  const messageId = payload.messageId;

  useEffect(() => {
    if (!payload.status) {
      payload.status = "running";

      const fileElem = document.getElementById("intmath1-image-input");
      if (fileElem.files) {
        const file = fileElem.files[0];
        getOCRResult(file, webAppUserId)
          .then((data) => {
            if (data.error) {
              updateMessage(data.error);
              return;
            }

            updateMessage(data);
            // Call AI Solver.
            actions.handleAIResponse(data);
          })
          .catch((error) => {
            if (error.message.includes("403")) {
              deleteMessage();
              // Display paywall.
              updateHasPaywall(true);
            }
            return;
          })
          .finally(() => {
            payload.status = "done";
          });
      }
    }
  }, [messageId]);

  const updateMessage = (data) => {
    setState((state) => {
      const newMessages = [...state.messages];
      const messageItem = newMessages.find((item) => item.id === messageId);
      messageItem.message = data;

      return { ...state, messages: newMessages };
    });
  };

  const deleteMessage = () => {
    setState((state) => {
      const newMessages = [...state.messages];
      const messageIdx = newMessages.findIndex((item) => item.id === messageId);
      newMessages.splice(messageIdx, 1);

      return { ...state, messages: newMessages };
    });
  };

  const getOCRResult = async (file, webAppUserId) => {
    const formData = new FormData();
    formData.append("image", file);
    formData.append("id", webAppUserId);
    return await fetchOCR(formData);
  };
};

export default OCRWidget;
