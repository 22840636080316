import UserIcon from "assets/images/icon-user.svg";
import BoltIcon from "assets/images/icon-bolt.svg";
import BoltHoverIcon from "assets/images/icon-bolt-orange.svg";
import CreditCardIcon from "assets/images/icon-credit-card.svg";
import CreditCardHoverIcon from "assets/images/icon-credit-card-orange.svg";
import SignOutIcon from "assets/images/icon-signout.svg";
import SignOutHoverIcon from "assets/images/icon-signout-orange.svg";

export const menuItems = [
  {
    title: <img src={UserIcon} alt="" />,
    // url: "/about",
    submenu: [
      {
        title: (
          <span className="intmath1-link-upgrade">
            <img src={BoltIcon} alt="" />
            <img src={BoltHoverIcon} alt="" className="intmath1-hover" />
            Upgrade
          </span>
        ),
        url: "https://buy.stripe.com/4gw4gl6wMcE2cmc8wQ",
        openNewTab: true,
        visibility: ["initial"],
      },
      {
        id: "login",
        title: <span className="intmath1-link-login">Login</span>,
        url: "https://solver.intmath.test/api/verify.php",
        openNewTab: false,
        visibility: ["initial"],
      },
      {
        title: (
          <span className="intmath1-link-subscription">
            <img src={CreditCardIcon} alt="" />
            <img src={CreditCardHoverIcon} alt="" className="intmath1-hover" />
            Manage Subscription
          </span>
        ),
        url: "https://billing.stripe.com/p/login/aEU3eA7MS7mKe408ww",
        openNewTab: false,
        visibility: ["subscription"],
      },
      {
        title: (
          <span className="intmath1-link-logout">
            <img src={SignOutIcon} alt="" />
            <img src={SignOutHoverIcon} alt="" className="intmath1-hover" />
            Logout
          </span>
        ),
        url: "https://billing.stripe.com/p/login/aEU3eA7MS7mKe408ww",
        openNewTab: false,
        visibility: ["subscription"],
      },
    ],
  },
];
