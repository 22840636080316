import { useEffect, useState, useRef } from "react";
import { createChatBotMessage } from "react-chatbot-kit";
import { Document, Page } from "react-pdf";

import { isiOS } from "../../../helpers/DeviceHelper";
import { aiCatchAllErrorMessage } from "../../../configs/messages.config";
import { fetchGraph } from "api/AIQueryAPI";

const GraphWidget = ({ payload, setState, updateHasPaywall, webAppUserId }) => {
  const [graphApiRunning, setGraphApiRunning] = useState(false);
  const lastMessageIdRef = useRef(payload.messageId);

  const iframeRef = useRef(null);

  /**
   * Fetch solver API.
   */
  useEffect(() => {
    const fetchData = async () => {
      const blob = await fetchGraph(
        payload?.query,
        payload?.answer,
        webAppUserId
      );
      const url = URL.createObjectURL(blob);
      return url;
    };

    const removeLastLoadingMessage = () => {
      const messagesElems = document.querySelectorAll(
        ".react-chatbot-kit-chat-message-container .react-chatbot-kit-chat-bot-message-container"
      );
      let lastMessageElem = messagesElems[messagesElems.length - 1];

      if (lastMessageElem) {
        const emptyParagraph = lastMessageElem.querySelector("p:empty");

        if (emptyParagraph) {
          lastMessageElem?.remove();
        }
      }
    };

    setGraphApiRunning(true);

    fetchData()
      .then((url) => {
        updateMessage(lastMessageIdRef.current, "");

        // Reference: https://tinytip.co/tips/html-pdf-params/
        iframeRef.current.src = `${url}#zoom=Fit&toolbar=1&navpanes=0`;
      })
      .catch((error) => {
        if (error.message.includes("403")) {
          // Display paywall.
          updateHasPaywall(true);
          return;
        }

        updateMessage(lastMessageIdRef.current, aiCatchAllErrorMessage);
      })
      .finally(() => {
        setGraphApiRunning(false);
        removeLastLoadingMessage();
      });
  }, []);

  /**
   * Create bot message.
   */
  const createMessage = (text) => {
    const botMessage = createChatBotMessage(text, {
      withAvatar: false,
    });

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));

    return botMessage.id;
  };

  /**
   * Update bot message.
   */
  const updateMessage = (messageId, text) => {
    setState((prevState) => {
      // const displayText = text.replace(/(?:\r\n|\r|\n)/g, "<br>");

      const newMessages = [...prevState.messages];
      const messageItem = newMessages.find((item) => item.id === messageId);
      // messageItem.message = displayText;
      messageItem.message = text;
      messageItem.loading = false;

      return { ...prevState, messages: newMessages };
    });

    // Remove message loading.
    const loader = document.querySelector(".chatbot-loader-container");
    loader?.remove();
  };

  if (isiOS()) {
    return (
      <div
        className={`iframe-container pdf-container ${
          iframeRef?.current?.src ? "show" : ""
        }`}
        test1={iframeRef?.current?.src}
      >
        <Document inputRef={iframeRef} file={iframeRef?.current?.src}>
          <Page
            pageNumber={1}
            renderTextLayer={false}
            renderAnnotationLayer={false}
          />
        </Document>
      </div>
    );
  }

  return (
    <div
      className={`iframe-container ${iframeRef?.current?.src ? "show" : ""}`}
    >
      <iframe
        ref={iframeRef}
        title="Graph"
        frameBorder="0"
        scrolling="no"
      ></iframe>
    </div>
  );
};

export default GraphWidget;
