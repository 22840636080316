/**
 * Detect if browser runs on iOS.
 *
 * @link https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#making_the_best_of_user_agent_sniffing
 */
export function isiOS() {
  const UA = navigator.userAgent;

  return /\b(iPad|iPhone|iPod)\b/.test(UA);
}
