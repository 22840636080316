// Reference: https://fredrikoseberg.github.io/react-chatbot-kit-docs/docs/advanced/chatbot-props#actionprovider

import React from "react";

import { createClientMessage } from "react-chatbot-kit";

const ActionProvider = ({ createChatBotMessage, setState, children }) => {
  const handleHello = () => {
    const botMessage = createChatBotMessage("Hello. Nice to meet you.");

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handleDog = () => {
    const botMessage = createChatBotMessage(
      "Here's a nice dog picture for you!",
      {
        widget: "dogPictureWidget",
      }
    );

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handleImagePreview = () => {
    let userMessage = createClientMessage("", {
      widget: "imageWidget",
    });
    userMessage.payload = { messageId: userMessage.id };

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, userMessage],
    }));
  };

  const handleOCR = () => {
    let userMessage = createClientMessage("", {
      widget: "ocrWidget",
      delay: 500,
    });
    userMessage.payload = { messageId: userMessage.id };

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, userMessage],
    }));
  };

  const handleAIResponse = (input) => {
    let botMessage = createChatBotMessage("", {
      widget: "aiResponseWidget",
      withAvatar: false,
    });
    botMessage.payload = { messageId: botMessage.id, input: input };

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handleGraphButton = (query, answer) => {
    let botMessage = createChatBotMessage("", {
      widget: "graphButtonWidget",
      withAvatar: false,
    });
    botMessage.payload = { messageId: botMessage.id, query, answer };

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handleGraph = (query, answer) => {
    let botMessage = createChatBotMessage("", {
      widget: "graphWidget",
      withAvatar: false,
    });
    botMessage.payload = { messageId: botMessage.id, query, answer };

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handleTutorButton = () => {
    let botMessage = createChatBotMessage("", {
      widget: "tutorButtonWidget",
      withAvatar: false,
    });
    botMessage.payload = { messageId: botMessage.id };

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            handleHello,
            handleDog,
            handleImagePreview,
            handleOCR,
            handleAIResponse,
            handleGraphButton,
            handleGraph,
            handleTutorButton,
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;
