export const fetchVerifyResponse = async (currentUrl) => {
  const data = {
    url: currentUrl,
  };
  const url = process.env.REACT_APP_VERIFY_API;
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error(response.status);
  }

  return response.json();
};
