const Paywall = () => {
  const chatUrl = process.env.REACT_APP_CHAT_URL;

  return (
    <div className="intmath1-upgrade">
      <div className="intmath1-inner">
        <h4>
          You've reached the maximum free solutions.
          <br />
          Upgrade to unlock <strong>Unlimited</strong> solutions.
        </h4>

        <div className="intmath1-plans">
          <div className="intmath1-plan">
            <div className="intmath1-plan-inner">
              <div className="intmath1-plan-price">
                <div className="current-price">
                  <span className="price">$9.95</span>
                  <span> /month</span>
                </div>
                <div className="original-price">&nbsp;</div>
              </div>

              <ul className="intmath1-plan-features">
                <li>Unlimited Solutions</li>
                <li>Step-by-step Work</li>
                <li>Available 24/7</li>
                <li>Academic Bonuses</li>
              </ul>

              <a
                href="https://buy.stripe.com/5kA9AFbR68nM1Hy4gC"
                target="_blank"
                rel="noopener noreferrer"
                className="intmath1-btn"
              >
                Upgrade Now
              </a>
            </div>
          </div>

          <div className="intmath1-plan">
            <div className="intmath1-deal">
              <span className="intmath1-deal-best">Best Deal</span>
            </div>

            <div className="intmath1-plan-inner">
              <div className="intmath1-plan-price">
                <div className="sales-price">
                  <span className="price">$39.95</span>
                  <span> /year</span>
                </div>
                <div className="original-price">
                  <span className="price">$119.14</span>
                  <span> /year</span>
                </div>
              </div>

              <ul className="intmath1-plan-features">
                <li>Unlimited Solutions</li>
                <li>Step-by-step Work</li>
                <li>Available 24/7</li>
                <li>Academic Bonuses</li>
              </ul>

              <a
                href="https://buy.stripe.com/eVa28d6wM9rQae43cx"
                target="_blank"
                rel="noopener noreferrer"
                className="intmath1-btn"
              >
                Upgrade Now
              </a>
            </div>
          </div>
        </div>

        <h4 style={{ textAlign: "center" }}>
          Already have an account?{" "}
          <a href={chatUrl} target="_blank" rel="noopener noreferrer">
            Sign In
          </a>
        </h4>
      </div>
    </div>
  );
};

export default Paywall;
