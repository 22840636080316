import { useState, useEffect, useRef } from "react";
import Dropdown from "./Dropdown";

import { Link } from "react-router-dom";

const MenuItems = ({ items, depthLevel }) => {
  const [dropdown, setDropdown] = useState(false);
  const [userStatus, setUserStatus] = useState("initial");

  let ref = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [dropdown]);

  const onMouseEnter = () => {
    window.innerWidth > 960 && setDropdown(true);
  };

  const onMouseLeave = () => {
    window.innerWidth > 960 && setDropdown(false);
  };

  const closeDropdown = () => {
    dropdown && setDropdown(false);
  };

  const renderLinks = (items) => {
    console.log("userStatus", userStatus);

    if (items.visibility.includes(userStatus)) {
      if (items.id === "login") {
        return <Link onClick={handleLogin}>{items.title}</Link>;
      } else {
        return (
          <Link to={items.url} target={items.openNewTab ? "_blank" : "_self"}>
            {items.title}
          </Link>
        );
      }

      /* return (
        <Link to={items.url} target={items.openNewTab ? "_blank" : "_self"}>
          {items.title}
        </Link>
      ); */
    }
  };

  const renderLinks2 = (items) => {
    console.log("userStatus", userStatus);

    if (userStatus === "subscription") {
      if (items.visibility.includes("subscription")) {
        return (
          <Link to={items.url} target={items.openNewTab ? "_blank" : "_self"}>
            {items.title}
          </Link>
        );
      }
    }

    if (!userStatus) {
      if (items.visibility.includes("initial")) {
        if (items.id === "login") {
          return <Link onClick={handleLogin}>{items.title}</Link>;
        } else {
          return (
            <Link to={items.url} target={items.openNewTab ? "_blank" : "_self"}>
              {items.title}
            </Link>
          );
        }
      }
    }
  };

  const handleLogin = async () => {
    // setUserStatus("subscription");
  };

  return (
    <li
      className="menu-items"
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={closeDropdown}
    >
      {items.url && items.submenu ? (
        <>
          <button
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? "true" : "false"}
            onClick={() => setDropdown((prev) => !prev)}
          >
            {window.innerWidth < 960 && depthLevel === 0 ? (
              items.title
            ) : (
              <Link to={items.url}>{items.title}</Link>
            )}

            {/* {depthLevel > 0 && window.innerWidth < 960 ? null : depthLevel >
                0 && window.innerWidth > 960 ? (
              <span>&raquo;</span>
            ) : (
              <span className="arrow" />
            )} */}
          </button>
          <Dropdown
            depthLevel={depthLevel}
            submenus={items.submenu}
            dropdown={dropdown}
          />
        </>
      ) : !items.url && items.submenu ? (
        <>
          <button
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? "true" : "false"}
            onClick={() => setDropdown((prev) => !prev)}
          >
            {items.title}{" "}
            {/* {depthLevel > 0 ? <span>&raquo;</span> : <span className="arrow" />} */}
          </button>
          <Dropdown
            depthLevel={depthLevel}
            submenus={items.submenu}
            dropdown={dropdown}
          />
        </>
      ) : (
        // <Link to={items.url} target={items.openNewTab ? "_blank" : "_self"}>
        //   {items.title}
        // </Link>

        renderLinks(items)
      )}
    </li>
  );
};

export default MenuItems;
