import { useEffect, useState } from "react";
import { MathJax, MathJaxContext } from "better-react-mathjax";
import { pdfjs } from "react-pdf";

import Solver from "components/Solver/Solver";
import { fetchVerifyResponse } from "api/VerifyAPI";

import "./App.scss";

// Configure PDF.js worker.
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

// Configure MathJax.
const config = {
  options: {
    enableMenu: true, // Enable/Disable the contextual menu.
    includeHtmlTags: {
      //  HTML tags that can appear within math
      br: "\n",
      wbr: "",
      "#comment": "",
    },
  },

  loader: { load: ["[tex]/html", "output/chtml"] },

  tex: {
    packages: { "[+]": ["html"] },
    inlineMath: [
      ["$", "$"],
      ["\\(", "\\)"],
    ],
    displayMath: [
      ["$$", "$$"],
      ["\\[", "\\]"],
    ],
    processEscapes: true, // use \$ to produce a literal dollar sign
    processEnvironments: true, // process \begin{xxx}...\end{xxx} outside math mode
  },
};

function App() {
  const [isDomainAllowed, setIsDomainAllowed] = useState(false);
  const [userEmail, setUserEmail] = useState(null);

  // Get params from parent window.
  const isWebApp = window.location.href.includes("v2=1");
  const uri = new URL(window.location.href);
  const webAppUserId = uri.searchParams.get("userId");
  const originalQuery = uri.searchParams.get("originalQuestion");

  useEffect(() => {
    verify();
    // getUserEmail();
  }, []);

  const verify = async () => {
    // Get parent window's location URL.
    let url = document.referrer || window.location.href;

    const uri = new URL(url);

    if (isWebApp) {
      setIsDomainAllowed(true);
      return;
    }

    return await fetchVerifyResponse(uri.href)
      .then(() => {
        setIsDomainAllowed(true);
      })
      .catch((error) => {
        // console.log("error", error);
      })
      .finally(() => {
        // console.log("done");
      });
  };

  const getUserEmail = () => {
    const uri = new URL(window.location.href);
    const email = uri.searchParams.get("id");
    setUserEmail(email);
  };

  const renderApp = () => {
    if (isDomainAllowed) {
      return (
        <div className="App">
          <Solver
            userEmail={userEmail}
            withinWebApp={isWebApp}
            webAppUserId={webAppUserId}
            originalQuery={originalQuery}
          />
        </div>
      );
    }

    return null;
  };

  return (
    <MathJaxContext version={3} config={config}>
      {renderApp()}
    </MathJaxContext>
  );
}

export default App;
