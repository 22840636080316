import { useEffect, useState, useRef } from "react";
import { createChatBotMessage } from "react-chatbot-kit";

import { aiCatchAllErrorMessage } from "../../../configs/messages.config";
import { fetchAIResponse } from "api/AIQueryAPI";

const waitingMessages = [
  "Please hang tight, I'm still working on it.",
  "Just pulling together all the steps for the solution now.",
];
const waitingIntervals = [1000 * 10, 1000 * 50]; // Intervals in milliseconds.

const AIResponseWidget = ({
  payload,
  setState,
  actions,
  updateHasPaywall,
  webAppUserId,
}) => {
  const [solverApiRunning, setSolverApiRunning] = useState(false);
  const lastMessageIdRef = useRef(payload.messageId);
  const waitingMessageIndexRef = useRef(0);

  /**
   * Fetch solver API.
   */
  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchAIResponse(payload?.input, webAppUserId);
      return data;
    };

    setSolverApiRunning(true);

    fetchData()
      .then((data) => {
        updateMessage(lastMessageIdRef.current, data.explanation);

        // Call Graph Button Widget.
        actions.handleGraphButton(payload?.input, data?.answer);
      })
      .catch((error) => {
        if (error.message.includes("403")) {
          // Display paywall.
          updateHasPaywall(true);
          return;
        }

        updateMessage(lastMessageIdRef.current, aiCatchAllErrorMessage);

        // Call Tutor Button Widget.
        actions.handleTutorButton();
      })
      .finally(() => {
        setSolverApiRunning(false);
      });
  }, []);

  /**
   * Display waiting messages until API is completed.
   */
  useEffect(() => {
    let timer;

    const showMessage = () => {
      // Check if API is still running.
      if (!solverApiRunning) {
        clearTimeout(timer);
        return;
      }

      // Update last message.
      const messageId = lastMessageIdRef.current;
      const text = waitingMessages[waitingMessageIndexRef.current];
      updateMessage(messageId, text);

      // Create new message.
      lastMessageIdRef.current = createMessage("");

      // Check if there are messages left to display.
      if (waitingMessageIndexRef.current >= waitingMessages.length - 1) {
        clearTimeout(timer);
        return;
      }

      // Prepare for next waiting message.
      waitingMessageIndexRef.current = waitingMessageIndexRef.current + 1;
      const interval = waitingIntervals[waitingMessageIndexRef.current];
      timer = setTimeout(showMessage, interval);
    };

    // Initial call to start the sequence.
    if (solverApiRunning) {
      timer = setTimeout(showMessage, waitingIntervals[0]);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [solverApiRunning]);

  /**
   * Create bot message.
   */
  const createMessage = (text) => {
    const botMessage = createChatBotMessage(text, {
      withAvatar: false,
    });

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));

    return botMessage.id;
  };

  /**
   * Update bot message.
   */
  const updateMessage = (messageId, text) => {
    setState((prevState) => {
      // const displayText = text.replace(/(?:\r\n|\r|\n)/g, "<br>");

      const newMessages = [...prevState.messages];
      const messageItem = newMessages.find((item) => item.id === messageId);
      // messageItem.message = displayText;
      messageItem.message = text;
      messageItem.loading = false;

      return { ...prevState, messages: newMessages };
    });

    // Remove message loading.
    const loader = document.querySelector(".chatbot-loader-container");
    loader?.remove();
  };
};

export default AIResponseWidget;
