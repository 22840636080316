export const fetchAIResponse = async (query, userEmail) => {
  const data = {
    query: query,
    id: userEmail,
  };
  const url = process.env.REACT_APP_QUERY_API;
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error(response.status);
  }

  return response.json();
};

export const fetchGraphable = async (query1, query2, userEmail) => {
  const data = {
    query1,
    query2,
    id: userEmail,
  };
  const url = process.env.REACT_APP_GRAPHABLE_API;
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error(response.status);
  }

  return response.json();
};

export const fetchGraph = async (query1, query2, userEmail) => {
  const data = {
    query1,
    query2,
    id: userEmail,
  };
  const url = process.env.REACT_APP_GRAPH_API;
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error(response.status);
  }

  return response.blob();
};
