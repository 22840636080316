import { useEffect, useState, useRef } from "react";

const tutoringUrl = process.env.REACT_APP_TUTORING_URL;

const TutorButtonWidget = ({ payload, setState, actions }) => {
  const lastMessageIdRef = useRef(payload.messageId);
  const showGraphButtonRef = useRef(null);

  useEffect(() => {
    const removeLastLoadingMessage = () => {
      const messagesElems = document.querySelectorAll(
        ".react-chatbot-kit-chat-message-container .react-chatbot-kit-chat-bot-message-container"
      );
      let lastMessageElem = messagesElems[messagesElems.length - 1];

      if (lastMessageElem) {
        const emptyParagraph = lastMessageElem.querySelector("p:empty");

        if (emptyParagraph) {
          lastMessageElem?.remove();
        }
      }
    };

    removeLastLoadingMessage();
  }, []);

  /**
   * Update bot message.
   */
  const updateMessage = (messageId, text) => {
    setState((prevState) => {
      const newMessages = [...prevState.messages];
      const messageItem = newMessages.find((item) => item.id === messageId);
      messageItem.message = text;
      messageItem.loading = false;

      return { ...prevState, messages: newMessages };
    });

    // Remove message loading.
    const loader = document.querySelector(".chatbot-loader-container");
    loader?.remove();
  };

  /**
   * Delete bot message.
   */
  const deleteMessage = (messageId) => {
    setState((prevState) => {
      const newMessages = [...prevState.messages];
      const messageIdx = newMessages.findIndex((item) => item.id === messageId);
      newMessages.splice(messageIdx, 1);

      return { ...prevState, messages: newMessages };
    });
  };

  return (
    <a
      href={tutoringUrl}
      target="_blank"
      rel="noopener noreferrer"
      className="intmath1-btn"
    >
      Ask a Tutor
    </a>
  );
};

export default TutorButtonWidget;
