// Reference: https://react.dev/reference/react-dom/server/renderToStaticMarkup

import React, { useEffect } from "react";
import { renderToStaticMarkup } from "react-dom/server";

const ImageWidget = ({ payload, setState }) => {
  const messageId = payload.messageId;

  useEffect(() => {
    const fileElem = document.getElementById("intmath1-image-input");
    if (fileElem.files) {
      const file = fileElem.files[0];
      const html = renderToStaticMarkup(Image(file));
      updateMessage(html);
    }
  }, [messageId]);

  const updateMessage = (data) => {
    setState((state) => {
      const newMessages = [...state.messages];
      const messageItem = newMessages.find((item) => item.id === messageId);
      messageItem.message = data;

      return { ...state, messages: newMessages };
    });
  };

  const Image = (file) => {
    const src = URL.createObjectURL(file);
    return (
      <div className="intmath1-image">
        <img src={src} alt="" />
      </div>
    );
  };
};

export default ImageWidget;
